import React from "react";

const Resume = () => {
  const experienceData = [
    {
      title: "Graphic Designer/Web Designer",
      company: "The Klingstedt Brothers, Co",
      location: "Canton, Ohio",
      dates: "2022 – Present",
      description: "As a Prepress and Graphic Designer at a printing company, my key responsibilities included preparing digital files for printing, ensuring that designs met all technical specifications and were properly formatted for different printing methods. I worked closely with clients to translate their concepts into visually compelling designs, focusing on layout, typography, color accuracy, and brand consistency. Additionally, I reviewed proofs and made necessary adjustments to ensure that the final print materials met high-quality standards. My role also involved coordinating with the production team to troubleshoot any issues with artwork, preparing files for both digital and offset printing, and maintaining strong communication with clients to ensure their expectations were met and deadlines were adhered to.",
    },
    {
      title: "Project Manager",
      company: "SKYCATCHFIRE",
      location: "North Canton, Ohio",
      dates: "2019 – 2022",
      description: "Organized web development projects, coordinated teams, and communicated with clients to deliver high-quality digital solutions on time and within budget. Worked closely and assisted with the design, development, and launch of projects from small to large scale. A key accomplishment in this role, was assisting in the design, development and implementation of a comprehensive merchandise management system for Mondelez, ensuring efficient inventory tracking and streamlined operations.",
    },
    {
      title: "Marketing Coordinator > Graphic Designer",
      company: "US Acute Care Solutions",
      location: "North Canton, OH",
      dates: "2015 – 2019",
      description: "As a graphic designer in the marketing department of US Acute Care Solutions, I developed visually compelling materials to support various marketing initiatives. My responsibilities included designing brochures, flyers, social media graphics, and promotional materials that effectively communicated the company’s services and brand message. Additionally, I adhered to brand guidelines and industry regulations to maintain a professional and compliant image.",
    },
  ];

  const educationData = [
    {
      degree: "BFA in Graphic Design",
      school: "University of Akron",
      location: "Akron, OH",
      dates: "May 2014",
    },
    {
      degree: "Associates Degree in Digital Graphic Arts",
      school: "Stark State College",
      location: "North Canton, OH",
      dates: "2010",
    },
  ];

  return (
    <div className="container mx-auto resume-container">
      <div className="bg-gray-100 p-8 rounded-lg shadow-lg mx-auto">
        {/* Header Section */}
        <header className="mb-8 text-center">
          <h1 className="text-4xl font-bold text-gray-800">Andrew Snyder</h1>
          <p className="text-lg text-gray-600">Graphic Designer | andrewsnyderdesign.com</p>
        </header>

        {/* Summary Section */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-700 border-b-2 border-gray-200 pb-2 mb-4">About Me</h2>
          <p className="text-gray-600 leading-relaxed">
            With a dynamic career spanning a decade, I’ve flourished as both a seasoned graphic designer and a proficient project manager. Over six years, I’ve crafted compelling visuals, while four years sandwiched between, I’ve orchestrated successful web projects with precision and creativity as a project manager.
          </p>
        </section>

        {/* Experience Section */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-700 border-b-2 border-gray-200 pb-2 mb-4">Experience</h2>
          <div className="space-y-6">
            {experienceData.map((job, index) => (
              <div key={index} className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
                <h3 className="text-xl font-semibold text-gray-800">{job.title}</h3>
                <p className="text-gray-600">{job.company} – {job.location}</p>
                <p className="text-sm text-gray-500 italic">{job.dates}</p>
                <p className="text-gray-600 mt-2">{job.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Education Section */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-700 border-b-2 border-gray-200 pb-2 mb-4">Education</h2>
          <div className="space-y-4">
            {educationData.map((edu, index) => (
              <div key={index} className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
                <h3 className="text-xl font-semibold text-gray-800">{edu.degree}</h3>
                <p className="text-gray-600">{edu.school} – {edu.location}</p>
                <p className="text-sm text-gray-500 italic">{edu.dates}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Resume;
