import React, { useState } from 'react'
import { RiMenu3Line } from "react-icons/ri";
import { RiCloseLargeLine } from "react-icons/ri";

import { Helmet } from "react-helmet";

import Footer from '../components/footer.jsx';
import Navlight from '../components/Navbarlight.jsx';
import Masonry from '../components/masonry.jsx';



const Work = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Andrew Snyder Design</title>
        <link rel="canonical" href="http://andrewsnyderdesign.com" />
      </Helmet>
      <Navlight />

      <Masonry />
      <Footer />
    </div>
  )
};

export default Work;