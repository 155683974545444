// src/Pages/Resume.js
import React, { useState } from 'react'
import { Helmet } from "react-helmet";
import Nav from '../components/Navbar.jsx';
import ResumeContent from '../components/ResumeContent.jsx'
import Footer from '../components/footer.jsx';
const Resume = () => {
  return (
    <div>
    <Helmet>
    <meta charSet="utf-8" />
    <title>Andrew Snyder Design</title>
    <link rel="canonical" href="http://andrewsnyderdesign.com" />
  </Helmet>
    <Nav />
    <ResumeContent />
    <Footer />
</div>
  );
};

export default Resume;