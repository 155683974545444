import React, { useState } from 'react'
import { RiMenu3Line } from "react-icons/ri";
import { RiCloseLargeLine } from "react-icons/ri";
import Nav from '../components/Navbarlight.jsx';
import Hero from '../components/hero.jsx';
import { Helmet } from "react-helmet";
import Form from "../components/form.jsx";
import Footer from '../components/footer.jsx';

const Contact = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Andrew Snyder Design</title>
        <link rel="canonical" href="http://andrewsnyderdesign.com" />
      </Helmet>
      <Nav />
      <Form />
      <Footer />
    </div>
  )
};

export default Contact;