import React, { useState, useEffect, useRef } from 'react';

const Masonry = () => {
  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Reference for the modal content
  const modalRef = useRef();

  // Data for images and descriptions
  const galleryData = [
    {
      id: 1,
      title: 'Image 1 Title',
      images: [
        'https://placehold.co/400x500',
        'https://placehold.co/400x500',
        'https://placehold.co/400x500',
      ],
      description: 'This is the description for image 1. Add more details here!',
      link: 'https://example.com/1',
    },
    {
      id: 2,
      title: 'Image 2 Title',
      images: [
        'https://placehold.co/400x500',
        'https://placehold.co/400x500',
      ],
      description: 'This is the description for image 2. Add more details here!',
      link: 'https://example.com/2',
    },
    {
      id: 3,
      title: 'Image 2 Title',
      images: [
        'https://placehold.co/400x500',
        'https://placehold.co/400x500',
      ],
      description: 'This is the description for image 2. Add more details here!',
      link: 'https://example.com/2',
    },
    {
      id: 2,
      title: 'Image 2 Title',
      images: [
        'https://placehold.co/400x500',
        'https://placehold.co/400x500',
      ],
      description: 'This is the description for image 2. Add more details here!',
      link: 'https://example.com/2',
    },
    {
      id: 2,
      title: 'Image 2 Title',
      images: [
        'https://placehold.co/400x500',
        'https://placehold.co/400x500',
      ],
      description: 'This is the description for image 2. Add more details here!',
      link: 'https://example.com/2',
    },
    {
      id: 2,
      title: 'Image 2 Title',
      images: [
        'https://placehold.co/400x500',
        'https://placehold.co/400x500',
      ],
      description: 'This is the description for image 2. Add more details here!',
      link: 'https://example.com/2',
    },
    // Add more gallery data as needed
  ];

  // Open modal with image data
  const openModal = (imageData) => {
    setModalData(imageData);
    setCurrentImageIndex(0);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Navigate to the next image in the slider
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === modalData.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Navigate to the previous image in the slider
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? modalData.images.length - 1 : prevIndex - 1
    );
  };

  // Close modal if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    // Add event listener
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div className="container mx-auto px-4 py-6 bg-red-400">
      {/* Gallery */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols- gap-10">
        {galleryData.map((item) => (
          <div key={item.id} className="gallery-item">
            <img
              src={item.images[0]} // Thumbnail image for the gallery
              alt={` ${item.id}`}
              className="w-full h-auto rounded-lg cursor-pointer transition-opacity opacity-0 duration-500 ease-in-out"
              loading="lazy"
              onLoad={(e) => e.target.classList.remove('opacity-0')} // Fade-in effect on load
              onClick={() => openModal(item)} // Open modal with modal image data
            />
          </div>
        ))}
      </div>

      {/* Lightbox Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
          <div
            ref={modalRef}
            className="bg-white p-6 rounded-lg max-w-2xl w-full relative"
          >
            {/* Image Slider */}
            <div className="relative">
              <img
                src={modalData.images[currentImageIndex]} // Current image in the slider
                alt="Modal Content"
                className="w-full h-[50vh] object-contain rounded-lg mb-4"
              />
              <button
                className="absolute top-1/2 left-2 transform -translate-y-1/2 text-white text-2xl bg-black bg-opacity-50 rounded-full p-2"
                onClick={prevImage}
              >
                ‹
              </button>
              <button
                className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white text-2xl bg-black bg-opacity-50 rounded-full p-2"
                onClick={nextImage}
              >
                ›
              </button>
            </div>

            {/* Modal Title */}
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">{modalData.title}</h2>

            {/* Description Paragraph */}
            <p className="text-lg text-gray-700 mb-4 leading-relaxed">{modalData.description}</p>

            {/* Conditionally render the button if the link exists */}
            {modalData.link && (
              <a
                href={modalData.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
              >
                View More
              </a>
            )}

            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-white text-3xl"
            >
              ×
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Masonry;
