import React, { useState, useEffect } from 'react';

const AgeParagraph = ({ birthDate }) => {
  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    const dayDifference = today.getDate() - birth.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  };

  const [age, setAge] = useState(calculateAge(birthDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setAge(calculateAge(birthDate));
    }, 24 * 60 * 60 * 1000); // Update daily

    return () => clearInterval(interval);
  }, [birthDate]);

  return (
    <div>
      <p>I am a graphic designer with {age} years of experience in the field. I’ve always found inspiration in the connections I build and the stories I encounter. As a graduate of the University of Akron, my passion for design has been shaped by both my roots and my experiences. I genuinely enjoy meeting new people, exchanging ideas, and talking about life, which fuels my work and brings a human-centered approach to my designs. Through each project, I aim to create visuals that resonate personally and professionally, making every design an opportunity to share in someone’s journey.</p>
    </div>
  );
};

// Usage in your app
const App = () => {
  return (
    <div>
      <h1>About Me</h1>
      <AgeParagraph birthDate="2012-03-11" /> {/* Replace with your actual birth date */}
    </div>
  );
};

export default App;
