import React from 'react';

const ProjectCard = ({ title, description, imgSrc, link }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
      <img src={imgSrc} alt={title} className="w-full h-64 object-cover" />
      <div className="p-6">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">{title}</h3>
        <p className="text-gray-600 mb-4">{description}</p>
        <a 
          href={link} 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-blue-500 font-medium hover:underline"
        >
          View Details
        </a>
      </div>
    </div>
  );
};

export default ProjectCard;
