import React from 'react';

const LinksSection = () => {
  const links = [
    { label: 'Resume', url: 'https://example.com/getting-started' },
    { label: 'LinkedIn', url: 'https://www.linkedin.com/in/andrewsnyderdesign' },
    { label: 'Github', url: 'https://github.com/AndrewAlllDay' },
  ];

  return (
    <div className='w-full h-[100vh] bg-white flex items-center justify-center p-8'>
      <div className='max-w-5xl w-full grid grid-cols-1 md:grid-cols-2 gap-10 bg-white shadow-lg rounded-lg p-10 ml'>
        {/* Left Column - Headline */}
        <div className='flex flex-col justify-center'>
          <h2 className='text-4xl font-bold text-gray-800 mb-4'>
            Useful Stuff
          </h2>
          <p className='text-gray-600'>
            Here’s a handpicked selection of resources to help you on your journey. Click on any link to dive deeper and find valuable information.
          </p>
        </div>

        {/* Right Column - List of Links */}
        <div className='flex flex-col space-y-8 ml-20'>
          {links.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target='_blank'
              rel='noopener noreferrer'
              className='flex items-center text-blue-600 font-medium text-lg group hover:text-blue-700 transition-colors'
            >
              {link.label}
              <span className='inline-flex items-center ml-3 text-blue-600 text-2xl transition-transform duration-300 transform group-hover:-translate-x-2'>
                →
              </span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LinksSection;
