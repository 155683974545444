import React from 'react';
import ProjectCard from './ProjectCard';  // Import the ProjectCard component

const Portfolio = () => {
  const projects = [
    {
      title: 'Retain Branding',
      description: "Revamped and refined the branding of Retain Loyalty's marketing materials to ensure consistency, clarity, and alignment with the company’s vision.".replace("&apos;", "'"),
      imgSrc: 'https://placehold.co/400x300',

    },
    {
      title: "Carlo&apos;s Trattoria".replace("&apos;", "'"),
      description: 'A creative project focused on user experience and design.',
      imgSrc: 'https://placehold.co/400x300',

    },
    {
      title: 'Oil Label Line',
      description: 'A web development project that integrates modern technologies.',
      imgSrc: 'https://placehold.co/400x300',

    }

  ];

  return (
    <section className="py-16 h-screen flex flex-col justify-center">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-3xl pb-5 font-semibold text-gray-800 mb-8">Recent Work</h2>

        {/* Center the card grid horizontally */}
        <div className="flex justify-center bg-b">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {projects.map((project, index) => (
              <ProjectCard
                key={index}
                title={project.title}
                description={project.description}
                imgSrc={project.imgSrc}

              />
            ))}
          </div>
        </div>

        {/* View More button */}
        <div className="mt-10">
          <a
            href="/work"
            className="bg-blue-500 text-white py-2 px-4 rounded-lg text-lg hover:bg-blue-600 transition transform hover:scale-105"
          >
            View More Projects
          </a>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
