import React from 'react'

const copywithmedia = () => {
  return (
    <div className='w-full h-screen'>
      <div className='m-w-[800px] w-full h-screen mx-auto text-center flex flex-col justify-center relative'>
        <div className='flex flex-col md:flex-row items-center justify-center mx-auto w-[75%]'>

          {/* Left Column */}
          <div className='w-full md:w-1/3 h-[200px] md:h-[580px] bg-white rounded-2xl flex flex-col justify-center pt-2 z-40 border-2 border-cyan-500 absolute md:relative md:left-5'>
            <div className='flex flex-col justify-center'>
              <p className='w-1/4 mx-auto font-Poppins font-bold text-3xl'>It's Me</p>
              <p className='mt-3 w-3/4 mx-auto text-sm font-Poppins'>
                Born and raised in Ohio, I’ve always found inspiration in the connections I build and the stories I encounter. As a graduate of the University of Akron, my passion for design has been shaped by both my roots and my experiences. I genuinely enjoy meeting new people, exchanging ideas, and talking about life, which fuels my work and brings a human-centered approach to my designs. Through each project, I aim to create visuals that resonate personally and professionally, making every design an opportunity to share in someone’s journey.
              </p>
            </div>
          </div>

          {/* Right Column (Image) */}
          <div className='md:w-2/3 w-full flex flex-col justify-center relative z-30'>
            <img className='bg-cover' alt='' src='https://images.unsplash.com/photo-1723001652492-b718bda91aaa?q=80&w=3269&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' />
          </div>

        </div>
      </div>
    </div>



  )
}

export default copywithmedia;
