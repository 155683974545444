import React from 'react'
import AgeParagraph from '../components/AgeParagraph';

const about = () => {
    return (
        <div name='about' className='w-full h-screen bg-black text-white snap-y snap-mandatory'>
            <div className='flex flex-col justify-center items-center w-full h-screen pt-30 md:pt-0'>
                <div className='max-w-[1000px] w-full px-4 grid grid-cols-2 gap-8'>
                    <div className='sm:text-right pb-8 pl-4'>
                        <p className='text-4xl font-bold inline border-b-4 border-cyan-500'>About</p>
                    </div>
                    <div></div>
                </div>
                <div className='max-w-[1000px] w-full px-6 grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <div className='font-bold text-xl md:text-xl sm:text-right pl-4'>
                        <p>Turning Ideas into Impactful Visuals</p>
                    </div>
                    <div className='flex flex-col mx-auto justify-center'>
                        <div className=' pl-4'><AgeParagraph />
                        </div>

                        <a href="/about"><button class="w-1/3 bg-cyan-500 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded mt-5 ml-4">Learn More</button></a>

                    </div>
                </div>
            </div>

        </div>

    )
}

export default about
